<template>
	<div class="role-management">
		<h1>Role Management</h1>
		<AddRoleForm @roleCreated="roleCreated"></AddRoleForm>
		<RolesList :key="listKey"></RolesList>
	</div>
	<!-- end container -->
</template>
<script>
import AddRoleForm from "../../components/forms/AddRoleForm.vue"
import RolesList from "../../components/lists/RolesList.vue"

export default {
	data() {
		return {
			listKey: 0,
		}
	},
	components: {
		RolesList,
		AddRoleForm,
	},
	methods: {
		roleCreated() {
			++this.listKey
		},
	},
}
</script>
