<template>
	<div class="roles-list list-view">
		<div class="list-header">
			<div class="col">
				<p>Current roles</p>
			</div>
		</div>
		<!-- end list-header -->
		<div class="roles items">
			<div class="grouping" v-for="role in rolesArray" v-bind:key="role.id">
				<article class="role item grid-container">
					<div class="col">
						<p class="role-name item-name"><router-link :to="{path: `/admin/role-management/${role.slug}/edit`}">{{ role.name }}</router-link></p>
					</div>
					<!-- end col -->
					<div class="col last edit">
						<span class="edit" title="Edit role">
							<router-link :to="{path: `/admin/role-management/${role.slug}/edit`}">
								<i class="fad fa-cog"></i>
							</router-link>
						</span>
					</div>
					<!-- end col -->
				</article>
			</div>
			<!-- end grouping -->
		</div>
		<!-- end roles -->
	</div>
	<!-- end roles-list -->
</template>
<script>
export default {
	data() {
		return {
			rolesArray: [

			],
		}
	},
    created(){
        this.getAllRoles();
    },
    methods: {
        async getAllRoles(){
            this.rolesArray = await this.$store.dispatch("roles/getAllRoles");
        }
    }
}
</script>
